.navbar {
    position: sticky;
    top: 2%;
    overflow: hidden;
    width: 82%;
    background: rgba(255, 255, 255, 0.994);
    box-shadow: 0px 3px 7px 0px rgb(144, 144, 144);
    backdrop-filter: blur(13px);
    border-radius: 0.7rem;
    margin: 0% 9%;
    z-index: 20;
}

.logo-container {
    margin-left: 0;
    padding-left: 20px;
    filter: none;
    vertical-align: middle;
}

.navigation-container {
    float: right;
    margin-top: 13px;
    padding-right: 20px;
}

.navigation-container .icon {
    display: none;
}

.login-nav {
    transition-timing-function: ease-in-out;
    transition: 0.3s;
    color: rgb(85, 85, 85);
    font-size: 10pt;
}

.login-nav:hover {
    transition-timing-function: ease-in-out;
    transition: 0.1s;
    color: rgb(0, 0, 0);
}

.join-button {
    transition-timing-function: ease-in-out;
    transition: 0.1s;
    background-color: rgb(90, 161, 255);
    color: white;
    padding: 5px 8px;
    border: 1px solid;
    border-radius: 6px;
}

.join-button:hover {
    transition-timing-function: ease-in-out;
    transition: 0.3s;
    background-color: white;
    color: rgb(90, 161, 255);
}

.image-main {
    margin-top: -65px;
}

@media (max-width: 640px) {
    .navigation-container a {
        display: none;
        position: relative;
    }
    .navigation-container a.icon {
        float: right;
        display: block;
        font-size: 20pt;
        margin-top: -3px;
        margin-right: -25px;
        color: rgb(105, 105, 105);
    }

    .navigation-container a {
      float: none;
      display: block;
      text-align: left;
    }
}







nav {
    position: sticky;
    top: 2%;
    overflow: hidden;
    width: 82%;
    background: rgba(255, 255, 255, 0.994);
    box-shadow: 0px 3px 7px 0px rgb(144, 144, 144);
    backdrop-filter: blur(13px);
    border-radius: 0.7rem;
    margin: 0% 9%;
    z-index: 20;
}

nav ul {
    display: flex;
}

nav ul li {
   list-style: none;
}

nav ul li a {
    float: left;
    display: block;
    text-decoration: none;
    font-size: 11pt;
    color: black;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}
nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
}


@media (max-width: 680px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
    }

    nav ul li a {
        margin: 0rem 24px;
    }
}